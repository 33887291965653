/* eslint-disable no-unused-vars */

import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "../../Hooks/UseAuth";
import {CacheStorage} from 'react-caches';
import ForgotPassword from "./ForgotPassword";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Button,
} from '@chakra-ui/react'
import { Input } from "postcss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Live_URL } from "../../config";

const Login = () => {
  const { user, signInUser, logOut ,sendEmailVerificationLink, setUserOTPStatus } = useAuth();
  console.log('setUserOTPStatus', setUserOTPStatus())
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [otp, setOtp] = useState(null)
  const [userPhoneNumber, setUserPhoneNumber] = useState(null)
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const Storage = new CacheStorage("GeneralStorage.KansaPItal");


  const handleOtpChange = (e) => {
    setOtp(e.target.value)
  }

  const handleModal = () => {
    onOpen()
  }

  const submitOtp = async () => {
    const otpdata = await Storage.GetCache("otp")
    if (otp == otpdata.data) {
      setUserOTPStatus(true)
      toast.success("Login successful!");
      await Storage.DeleteCache("otp")
        navigate(from, { replace: true });
    } else {
      setUserOTPStatus(false)
      toast.error("Wrong OTP")
    }
  }

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (dataForm) => {
    const email = dataForm.email;
    const password = dataForm.password;

    try {
      signInUser(email, password)
        .then(async (result) => {

          console.log('result', result)
          const user = result?.user
          if (user?.emailVerified) {
            const response = await axios.get(`${Live_URL}/api/user/${user.email}`);
              setUserPhoneNumber(parseInt(response.data.phonenumber));
              const responses = await axios.post(`${Live_URL}/api/login/otp`, { phone: userPhoneNumber });
              const storageresponse = await Storage.saveCache("otp", responses.data.otp)
              handleModal()
            console.log('response', storageresponse)
            
          } else {
            sendEmailVerificationLink(user).then(() => {
              toast.success("Check your email for verification.");
            })
            logOut();
            toast.error("Please verify your email before logging in.");
            navigate('/check-email', { replace: true });
          }
          reset()
        })
        .catch((error) => {
          toast.error("Failed to login: Wrong email/password", error);
      })

    } catch (error) {
      toast.error("Failed to login: ", error.message);
    }
  };


  

  // const handlePasswordReset = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await ForgotPassword(email)
  //   } catch (err) {
  //     setMessage('');
  //     setError(err.message);
  //   }
  // };



    return (
      <>
        <Toaster />
        <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10 bg-cardBg mx-auto my-5 text-gray-800">
          <div className="mb-8 text-center">
            <h1 className="my-3 text-4xl font-bold">Sign in</h1>
            <p className="text-sm text-gray-600">
              Sign in to access your account
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className=" mb-2 text-sm">
                  Email address
                </label>
                <input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="leroy@jenkins.com"
                  className="w-full px-3 py-2 border rounded-md bg-gray-100  "
                />
              </div>
              <div>
                <div className="flex justify-between mb-2">
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                  <p
                  onClick={() => document.getElementById('my_modal_3').showModal()}
                    className="text-xs hover:underline text-gray-600"
                  >
                    Forgot password?
                  </p>
                </div>

                <input
                  type="password"
                  {...register("password")}
                  placeholder="*****"
                  className="w-full px-3 py-2 border rounded-md  bg-gray-50 text-gray-800"
                  />
              </div>
            </div>

            <input
              type="submit"
              className="w-full px-8 py-3 font-semibold rounded-md bg-yellow-500 hover:bg-yellow-600  text-black"
              value="Sign in"
              />
          </form>
              <ForgotPassword/>
          <p className="px-6 mt-4 text-sm text-center text-gray-600">
            {"Don't have an account yet?"}
            <Link to="/createAccount" className="hover:underline text-violet-600">
              Sign up
            </Link>
            .
          </p>
        </div>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> One Time Password Verification Reqired</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <text htmlFor="otpId" className="text-center m-auto">Enter The OTP Sent to {userPhoneNumber}</text>
              <input id="otpId" type="number" value={otp} onChange={handleOtpChange} placeholder="Type here" className="input input-bordered w-full max-w-xs ml-7" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='whatsapp' mr={3} onClick={submitOtp}>
              Verify
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
    );
};

export default Login;
